<template>
  <div class="login">
    <AppBar />
    <v-content class="mx-auto mt-5">
      <v-layout row v-if="error">
        <v-flex xs12 sm6 offset-sm3>
          <!-- <app-alert @dismissed="onDismissed" :text="error.message"></app-alert> -->
          <v-alert v-if="error" type="error" dismissible @input="onDismissed" :value="true">{{error}}</v-alert>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs12 sm6 offset-sm3>
          <v-card :loading="loading">
            <v-card-title>
              <h1>Login</h1>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid">
                  <v-layout row>
                    <v-flex xs12>
                      <v-text-field v-model="email" :rules="emailRules" name="email" label="Email" id="email" type="email" prepend-icon="mdi-email" required></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row>
                    <v-flex xs12>
                      <v-text-field v-model="password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="passwordRules" :type="showPassword ? 'text' : 'password'" name="password" label="Password" id="password" hint="At least 8 characters" prepend-icon="mdi-lock" counter @click:append="showPassword = !showPassword"></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn :loading="loading" color="success" class="mr-4" @click="submit" :disabled="!valid">Login</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="clear">clear</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <!-- <v-overlay :value="loading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>-->
      </v-layout>
    </v-content>
  </div>
</template>
<script>
import AppBar from "@/components/AppBar.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      valid: false,
      email: "",
      emailRules: [v => !!v || "E-mail is required", v => /.+@.+\..+/.test(v) || "E-mail must be valid"],
      password: "",
      passwordRules: [v => !!v || "Password is required"],

      showPassword: false,

      error: null,

      loading: false
    };
  },
  created() {
    
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
      isAuthenticated: "isAuthenticated"
    })
  },
  components: {
    AppBar
  },
  methods: {
    onDismissed() {
      this.error = null;
    },
    submit() {
      this.$refs.form.validate();

      const user = {
        email: this.email,
        password: this.password
      };
      console.log(user);

      this.loading = true;

      try {
    


      let response = this.$store.dispatch("login", user).then(
        () => {
          console.log("redirection vers dashboard");
          this.onDismissed();
          this.loading=false;
          this.$router.push({ path: "/school/1" });
        },
        error => {
          console.error("Got nothing from server. Prompt user to check internet connection and try again" + error);
          this.error = error;
          this.loading=false;
        }
      );

      console.log(response);

      } catch(e) {
    console.log(e);
}

      //this.$router.replace({ path: "/school/1" });

      // firebase
      //   .auth()
      //   .signInWithEmailAndPassword(this.email, this.password)
      //   .then(data => {
      //     this.$router.replace({ path: "/parents/"+data.user.uid });
      //     console.log(data.user)
      //   })
      //   .catch(err => {
      //     this.error = err.message;
      //   });
    },
    clear() {
      this.email = "";
      this.password = "";
      this.$refs.form.reset();
    }
  }
};
</script>
